import * as React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import AboutLending from '../../components/aboutLending/AboutLending'
import usePrismicServices from "../../hooks/usePrismicServices";

const InvestmentCompany = () => {
  const { language } = useTranslation()
  const { investmentCompanyAndFund } = usePrismicServices()
  return(
    <Main>
      <Container
        maxW="7xl"
        px="5"
        mb="3.75rem">
        <Seo
          title={investmentCompanyAndFund.header}
          description={investmentCompanyAndFund.header}
          link={`https://www.fsm.am/${language}/investment-company-and-fund`}
        />
        <SmallNav>
          {investmentCompanyAndFund.header}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{base: '2xl', sm: "3xl"}}
          fontWeight="900"
          mt={{base: '3', sm: "5"}}
          mb="10">
            {investmentCompanyAndFund.header}
        </Heading>
        <AboutLending {...investmentCompanyAndFund} />
      </Container>
    </Main>
  )
}

export default InvestmentCompany

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`